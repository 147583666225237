@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700&family=Open+Sans&family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');


.icon {
  font-size: 1.5rem;
  color: #000;
  margin: 0 0.6vw 0 0.5vw;
}

.icons {
  font-size: 1.5rem;
  color: #000;
  margin-right: 1rem;
  height: max-content;
}

.icons2 {
  font-size: 1.5rem;
  color: #123872;
  height: max-content; 
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-image: url(../src/assets/images/main.png); */
  /* background-repeat: no-repeat; */
  background-size: contain;
  background-size: 100% 100%;
  
}

.box {
  transition: 1s;
}

.box:hover {
  transform: scale(1.1);
  background-color: #fff;
  box-shadow: #f2f2f2 0px 0px 5px;
  z-index: 3;
}

.box2 {
  transition: 1s;
}

.box2:hover {
  transform: scale(1.1);
  /* box-shadow: #f2f2f2 0px 0px 5px; */
  z-index: 3;
}

.box-practice {
  transition:2s;
}

.box-practice:hover {
  transform: scale(1.1);
  box-shadow: #f2f2f2 0px 0px 5px;
  z-index: 3;
}

.box1:hover {
  box-shadow:  #cecece 0px 0px 10px;
}

.nav-links {
  display: flex;
  padding: 6px 0px 6px 6px;
  
  flex-direction: row;
  border-radius: 8px;
}

.nav-links2 {
  display: flex;
  background-color: #f2f2f2;
  padding: 10px 14px;
  gap: 10px;
  border-radius: 8px;
  border-radius: 8px;
}
.search {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  z-index: 10;
}
.text-footer {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.03rem;
  padding-bottom: 2vh;
 
}
.caption {
  font-family: 'Poppins', sans-serif;
  line-height: 80px;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 50px;
}

.caption-mobile {
  font-family: 'Poppins', sans-serif;
  line-height: 80px;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 50px;
}

.description {
  font-family: 'Mulish', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.05rem;
}

.main-description {
  font-family: 'Mulish', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.05rem;
}

.sub-description {
  font-family: 'Mulish', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.05rem;
}

.description1 {
  font-family: 'Mulish', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.05rem;
}

.description2 {
  font-family: 'Mulish', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.05rem;
}

.caption-capabilities {
  font-family: 'Mulish', sans-serif;
  font-size: 2rem;
  letter-spacing: 2%;
  font-weight: 400;
}

.caption-pathways {
  font-family: 'Mulish', sans-serif;
  font-size: 30px;
  letter-spacing: 2%;
  font-weight: 700;
  line-height: 40px;
}

.caption-pathways2 {
  font-family: 'Mulish', sans-serif;
  font-size: 20px;
  letter-spacing: 2%;
  font-weight: 500;
  line-height: 20px;
  color: #4493f1;
}

.caption-pathways3 {
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  letter-spacing: 2%;
  font-weight: 500;
  line-height: 20px;
  color: #000;
  opacity: 0.8;
}

.caption-form {
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  letter-spacing: 2%;
  line-height: 20px;
  text-transform: uppercase; 
}
.caption-about {
    font-family: 'Mulish', sans-serif;
    font-size: 1.4rem;
    letter-spacing: 2%;
    font-weight: 500;
    line-height: 20px;
}
.caption-about-sub {
  font-family: 'Mulish', sans-serif;
  font-size: 1.05rem;
  font-weight: 400;
  line-height: 40px;
  text-justify: justify;

}

.caption-partners {
  font-family: 'Mulish', sans-serif;
  font-size: 15px;
  letter-spacing: 2%;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 2vh;
}

.main-caption-partners {
  font-family: 'Mulish', sans-serif;
  font-size: 17px;
  letter-spacing: 2%;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 2vh;
}

.caption-partners2 {
  font-family: 'Mulish', sans-serif;
  font-size: 17px;
  letter-spacing: 2%;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 2vh;
  opacity: 0.8;
}
.pathways {
  background-image: linear-gradient(180deg, #FDFEFF 46.84%, #DEEFFF 100%);
  height: 150vh;
}

.perspective {
  background-image: linear-gradient(180deg, #FDFEFF 46.84%, #DEEFFF 100%);
  height: max-content;
}

.perspectives {
  /* background-image: url(../src/assets/images/news-6.png); */
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: flex-start;
  width: 100%;
}

.aboutus {
  background-image: linear-gradient(180deg, #FDFEFF 46.84%, #DEEFFF 100%);
  height: 300vh;
}
.caption-home {
  font-family: 'Mulish', sans-serif;
  line-height: 80px;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 50px;
  margin-top: 10vh;
  padding-bottom: 2vh;
}

.caption-home1 {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.02rem;
  font-weight: 400;
  font-size: 30px;
  margin-top: 4vh;
  padding-bottom: 2vh;
}

.caption-home-sub {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 25px;
  opacity: 0.8;
}

.caption-home-sub2 {
  font-family: 'Mulish', sans-serif;
  line-height: 30px;
  letter-spacing: 0.06rem;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 2vh;
}

.caption-home-sub3 {
  font-family: 'Mulish', sans-serif;
  line-height: 30px;
  letter-spacing: 0.06rem;
  font-weight: 400;
  font-size: 12px;
}

.caption-news {
  font-family: 'Mulish', sans-serif;
  line-height: 30px;
  letter-spacing: 0.06rem;
  font-size: 14px;
}

.caption-card {
  font-family: 'Mulish', sans-serif;
  line-height: 25px;
  letter-spacing: 0.03rem;
  font-weight: 400;
  font-size: 16px;
}

.year {
  font-family: 'Mulish', sans-serif;
  color: #000694;
  line-height: 22.5px;
  letter-spacing: 0.06rem;
  font-weight: 400;
  font-size: 18px;
  padding: 5vh 0;
}

.text-navbar {
  font-family: 'Mulish', sans-serif;
  /* color: #000; */
  line-height: 22.5px;
  letter-spacing: 0.02rem;
  font-weight: 400;
  font-size: 1.2vw;
  padding: 5vh 0;
}

.home {
  background-image: linear-gradient(180deg, #FDFEFF 46.84%, #DEEFFF 100%);
}

.text-button {
  font-family: 'Mulish', sans-serif;
  letter-spacing: 0.02rem;
  font-weight: 400;
  font-size: 16px;
}

.about {
  background: transparent;
  display: flex;
  flex-direction: column;
  height: max-content;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
  
}

.pages {
  background: linear-gradient(180deg, #FDFEFF 46.84%, #DEEFFF 100%);
  padding-top: 10vh;
}

.home .practice {
  align-items: flex-end;
  justify-content: end;
  padding-left: 5vw;
  padding-right: 0vw;
}

.home .practice .caption {
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  margin-top: 2.5vh;
  padding-bottom: 5vh;
  width: 20vw;
  border-bottom: 1px solid #000;
}

.sidebar {
  background-color: #f2f2f2;
  
}

.carousel-home {
  background-image: url(../src/assets/images/Rectangle.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 448px;
  padding: 0 5vw;
  font-size: 1rem;
  color: #fff;
}

.text-carousel {
  font-family: 'Mulish', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  color: #000;
}

.select-practice {
  background: #4A4F57;
  color: white;

}

.text-practice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 400;
  color: #000;
}

.text-practice-sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.06rem;
  font-weight: 400;
  color: #000;
}

.text-about {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02rem;
  text-align: justify;
  color: #000;
  
}

.text-about-sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02rem;
  color: #000;
}

.bg-value {
  background: rgba(223, 240, 255, 0.8);
}

.slick-dots li button:before {
  font-size: 1.2vw !important;
  margin-top: 12vh;
}

.dark .dark\:slick-dots-dark li button:before {
  font-size: 1.2vw !important;
  margin-top: 12vh;
  color: white !important;
  opacity: 100%;
}

.slick-dots li.slick-active button:before {
  color: #000 !important;
}

.capabilities-one:hover {
  /* background-image: url(../src/assets/images/practice/one.png); */
  background-repeat: no-repeat;
  color: #000;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-size: contain;
  background-size: 100% 100%;
  
}

.captch {
  background-image: url(../src/assets/images/captcha.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  margin-bottom: 3vh;
} 

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
  width: 100%;
	height: 80px;
	padding: 0 2rem;
	background-color: white;
	color: black;
}

header .nav-btn {
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: black;
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 2vh;
		background-color: white;
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  font-family: 'mulish', sans-serif;
  background-color: #4493f1;
  border: 1px solid #4493f1;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.upload-box {
  width: 30vw;
  height: max-content;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5vw 0.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

::-webkit-file-upload-button {
  color: #4493f1;
  background-color: #fff;
  border: 1px solid #4493f1;
  border-radius: 5px;
  padding: 0.5vw 0.3vw;
  cursor: pointer;
}

.select-css {
	display: block;
	margin: 0;
	border: 1px transparent;
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #4a4f57;
	background-image: url('../src/assets/images/arrow_drop_down_white_24dp.svg');
  background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: 1.5rem auto, 100%;
}

.select-css-partner {
	display: block;
	margin: 0;
	border: 1px solid #d9d9d9;
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #f9f9f9;
  color: rgb(107 114 128);
	background-image: url('../src/assets/images/arrow_drop_down_black_24dp.svg');
  background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: 1.5rem auto, 100%;
  opacity: 90%;
}

.dark .dark\:select-css-partner-dark {
	display: block;
	margin: 0;
	border: 1px solid #d9d9d9;
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #f9f9f9;
  color: rgb(107 114 128);
	background-image: url('../src/assets/images/arrow_drop_down_white_24dp.svg');
  background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: 1.5rem auto, 100%;
  opacity: 90%;
}

.main-navbar {
  background-image: url('../src/assets/images/logo1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.dark .dark\:main-navbar-dark {
  background-image: url('../src/assets/images/logo-dark.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.searchBox input {
  border: none;
  outline: none;
  background: none;
  width: 8vw;
}

.searchBoxMobile input {
  border: none;
  outline: none;
  background: none;
  width: 12vw;
}

.paginationBttns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}



.paginationA {
  color:rgba(50, 71, 92, 0.87);
  cursor: pointer;
  padding: 1.1rem 1.5rem;
}

.paginationA:hover {
  background-color: rgba(50, 71, 92, 0.04);
  color: #32475C;
  border-radius: 100%;
}

.paginationActive {
  background-color: rgba(50, 71, 92, 0.04);
  border-radius: 100%;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
}

.img-fluid {
  max-width: 40%;
  height: auto;
}